@tailwind base;

@import '@fontsource/dm-sans/index.css';
@import 'react-virtualized/styles.css';
@import 'rc-slider/assets/index.css';

@import './variables.css';

@tailwind components;

@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.striped-background {
  background: repeating-linear-gradient(45deg, #161522, #161522 10px, #0d0415 10px, #0d0415 20px);
}

/* *::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track-piece:start {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #BFBFBF;
} */